@import "../../../Layout/common";
@import "../../../Components/Rules/icons";

/*
 * Rule styles specific to routing
 */

#routing-rule-manager{

  .entity-info-section{
    .card-body{padding: 1rem;}
    .entity-info-cell{
      white-space: nowrap;
      margin-left:3rem;
      label{
        margin-bottom: 0;
      }
    }

  }

  .col-rule-tree{
    //margin-right:1rem;

    //padding-right: 1rem;

    .rule-bid{
      //AKA margin, overriding style in shared rules styles
      //For routing margin, we don't show the arrow, and indicate red only if OVER 100
      &.bid-modifier{
        flex-basis:auto!important;
        border-top-left-radius: 4px!important;
        border-bottom-left-radius: 4px!important;
      }

      &.over-100{
        background-color: #990000!important;
      }

    }

    .rule-actions{ //ellipsis icon rule menu
      min-width:1rem;
      text-align: center;
      .btn{
        padding:0 5px;
      }
    }
  }

  /* column with header, rule configuration tabbed form */
  .col-rule-config{
    overflow:hidden; // keeps action menu slider hidden
    //In routing rules modal, actions panel slides in from left side of col-rule-config
    .actions-panel{
      left:0;
      border-right:1px solid $gray-300;
      box-shadow: inset 10px 0 10px -10px rgba(0, 0, 0, 0.12);
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
      z-index:800;

      &.open{
        -webkit-transform: translate3d(-16px, 0, 0);
        transform: translate3d(-16px, 0, 0);
      }

    }

    .actions-overlay {
      z-index: 700;
    }

    //Contains TabContent, TabPanes, all forms and widgets
    .rule-config {
      //label{color:#8e9092;}

      //.caps{
      //  .caps-container{
      //
      //    .cap-row{
      //      padding:0.3rem 0.2rem;
      //      border-bottom:1px solid $gray-200;
      //      &:last-child{
      //        border-bottom:none;
      //      }
      //      //.cap-value{
      //      //  width:6rem;
      //      //}
      //      .timeframe{
      //        .form-control{
      //          display:inline-block;
      //          width:150px;
      //        }
      //      }
      //
      //    }
      //
      //  }
      //
      //}

      .rule-summary {

        .summary-item {
          &.flow-control-summary{
            .flow-setting{
              font-size:1rem;
              font-weight:700;
            }
          }
        }

      }

      .integration-container.enrichment {
        min-width:initial;
      }

    } /* .rule-config */

  }
} /* #routing-rule-manager*/

