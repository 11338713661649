@import "../../../Layout/common";

.routing-rules-panel{
  &.offcanvas-end {
    width:70vw!important;
  }

  .col-rule-tree {
    background: #F2F6FF 0% 0% no-repeat padding-box;
    border: 1px solid #DBE5EF;
    border-radius: 3px;
    opacity: 1;
    margin-right:1em;
    padding:0.75em;
    .tree {
      background-color: $white;
      padding:0.5em;
    }
  }
}
