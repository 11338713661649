@import "../../../Layout/common";

.routing-mapper {

  //Flow banner when visible, will appear to be part of the flow-container canvas
  .flow-banner {
    background-color: #F2F6FF;
    padding:20px 20px 0 20px;
    svg.dog {
      color:$danger;
    }
  }
  .flow-container {
    background-color: #F2F6FF;
  }
  .react-flow {
    overflow-x:hidden!important;
    overflow-y:auto!important; //for implementing scrollbar

    .react-flow__renderer{
      background-color:  #F2F6FF;
    }

    .react-flow__edge.selectable:hover .react-flow__edge-path,
    .react-flow__edge.selectable.selected .react-flow__edge-path {
      //stroke: var(--xy-theme-edge-hover);
      stroke:darkred;
    }

    .react-flow__handle {
      //background-color: var(--xy-handle-background-color-default);
      background-color: $blue-600;
      width: 10px;
      height: 10px;
      border: 2px solid white;
    }
    .react-flow__edge-path {
      stroke: $gray-600;
    }

    .node {
      position:relative;
      width:320px;
      border: 1px solid #DBE5EF;
      background-color: $white;
      font-size:14px;
      padding: 0.75rem;
      border-radius: 4px;


      // when the node is the focus of the map
      &.route-focus {
          //background-color:rgba(#DAE7F8, 0.7);// lightyellow;
          border: 2px solid rgba($blue-400, 0.8);
      }

      &.archived {
        background-color: $gray-200;
        border: 1px solid $gray-500;
        .node-title {
          color:$gray-600;
        }
        .node-detail {
          color:$gray-600!important;
        }
      }
      //&.paused {
      //  background-color: $yellow-100;
      //  border: 1px solid $orange-300;
      //}


      &.selected{

          background-color: $primary!important;
          color:$white;
          border-color: steelblue!important;
          .btn {
            color: $white;
          }

      }
      &.alert {
        background-color: $red-100;
        border: 1px solid $red-300;
        .node-title, .alert-message {
          color:$danger;
          svg, .help-popper-icon {
            color:$gray-700;
          }
        }
        //.help-popper-icon {
        //  color:$gray-700;
        //}
      }
      &.info {
        background-color: transparent;
        font-style: italic;
        color:$gray-700;
        border-color:transparent;
      }

      &.flow-header {
        //border:none;
        padding:0.5rem;
        background-color: tint-color(#DBE5EF, 5%);
        margin-bottom:0.5rem;
        &.active {
          //background-color: $primary;
          //color:$white;
          .title {
            color:$primary;
          }
        }
        .title {
          font-size:1rem;
          font-weight:600;
          color:$gray-700;
        }
      }

      &.source-token {
        .token {
          display:inline-block;
          max-width:182px;
          overflow:hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

      }

      .status-badge {
        position:absolute;
        top:-8px;
        right:-6px;
        &.archived {
          background-color: $red-100!important;
          border:1px solid $red-500;
          color:$red-500!important;
        }
        &.paused {
          background-color: $yellow-100 !important;
          border:1px solid $orange-600;
          color:$orange-600!important;
        }
      }

      .icon-col{
        padding-right:4px;
        text-align: center;
        min-width:40px;
      }

      .node-header {
        display:flex;

        .node-title {
          display:flex;
          justify-content: space-between;
          font-weight:600;
          .no-relation {
            color:$danger;
          }
        }

        .actions {
          white-space: nowrap;
          text-align: right;
          .btn {
            padding:0;
            margin:0 4px;
          }
        }
      }

      .node-detail {
        color:$gray-700;

        margin-top:0.5rem;
       // padding: 0 0.5rem 0.5rem 0.5rem;
        .icon-col {
          width:40px;
        }
        .mst-data-row {
          font-size:0.8rem;
        }

        //.btn-link {
        //  color:$gray-600!important;
        //}
        svg {
          margin-right:6px;
        }
          label {
            display:inline-block;
            width:50px;
            font-weight: 600;
          }
          //time {
          //  display:block;
          //  margin-top:10px;
          //}

      }

      .node-expand {

        font-size: 0.8rem;
        background-color: $white;
        .rules{

          .rule {
            border:1px solid $gray-300;
            border-radius: 4px;
            background-color: $white ;
            padding:0.4rem;
            margin:6px 0;
            //font-size:8px!important;
          }
        }

      }

      //Custom handle on ChannelNode for clicking to get orders
      .order-handle {
        cursor: pointer;
        background-color: #fff;
        border: 4px solid #F2F6FF;
        padding: 2px;
        width: 28px;
        height: 28px;
        min-width: 28px;
        min-height: 28px;
        line-height: 18px;
        text-align: center;
        &:hover {
          //color:$primary;
          border-color:$gray-200;
          background-color: $primary!important;
          color:$white!important;
        }
      }

    }

    //used in the header node but below that class element
    .applied-filters {
      svg {
        color:$primary;
      }
      color:$gray-700;
      padding:0 0.5rem;

    }
  }
}


//ClassName on tooltip goes on the 'fade' that wraps it
// has descendants 'tooltip' > 'tooltip-inner'  and 'arrow tooltip-arrow'
.flow-tooltip {
  .tooltip {
    opacity:1!important;
    .tooltip-inner {
      border:1px solid $gray-600!important;
      border-radius:12px!important;
      padding: 0.5rem!important;
      color:$gray-700!important;
      background-color: $white!important;

    }
    .tooltip-arrow {
      border-color: $gray-600!important;
    }
  }
}
