@import "./common";
// @import '../../node_modules/bootstrap/scss/mixins/_hover.scss'; REMOVED v5


  #main-content{
    z-index:10;
    padding-top:70px;
    min-height:100%;
    height:auto !important;
    margin: 0 2rem -130px;
  }


  //wrap long strings
  .dont-break-out {
    /* https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/ */
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;

  }

  a.breadcrumb-item, a:visited.breadcrumb-item {
    color: $primary;
    &:hover{
      text-decoration: underline;
    }
  }

  // Link style matches custom "xs" button size (MST Lib),
  a.link-xs {
    font-size: 0.7rem;
  }

/* #################################
* LAYOUT BLOCKS
* #################################
*/

/* PAGE-SECTION:
A visually distinct block of content. Most pages have one section but some might have more.
  Implemented in component PageSection, which wraps the component(s) that render content */
@keyframes fadeInSection { from { opacity: 0; } to { opacity: 1; } }

.page-section{

  animation-name: fadeInSection; animation-duration: 400ms; animation-timing-function: linear;
  padding:$card-spacer-y $card-spacer-x ;
  .page-section-header {
    margin-bottom: 1rem;

    .page-title {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      .subtitle{
        margin-left:0.5rem;
        font-size:16px;
        text-transform: capitalize;
        font-weight:500;
        color:$gray-700; // blue: #1989d0, or $primary
      }
    }

    .section-save-actions{
      margin-top:12px;
      .btn{
        margin:0 6px;
        &:first-child{
          margin-left:0;
        }
      }
    }

    .page-section-tools{
      display:flex;
      flex-wrap:nowrap;
      justify-content: flex-end;
      align-items:center;
    }
  }
 // .card {
    //position:relative;
    //min-height:100px;
    background-color:$content-bg;
    box-shadow: 1px 1px 1px 0 rgba(90, 90, 90, 0.25);

  &.overflow-section{
    // inline-block allows super-wide table to neatly overflow 100% width
    // However, this can cause problems when we try to position a spinner or other
    // overlay, so we tack on the 'loading' class to temporarily hide the extended width.
    display:inline-block;
    min-width:100%;
    &.loading{
      display:inherit;
      overflow:hidden;
      .reports-table .table{
        overflow:hidden;
      }
    }
  }

  &.expandable {
    overflow:hidden;
    transition: height 0.4s ease-out;
    &.show{
      transition:0.4s;
      overflow:visible;
    }
  }

  &.collapsed {
   // background-color:$gray-200;
   // color:$gray-500;
    overflow:hidden;
    transition: 0.4s;
    padding-bottom:0.5rem;
    padding-top:0.5rem;
    h5{text-transform: uppercase;padding:0;margin:0;}
  }

}



//not a separate page section, goes directly above a table
.above-table-filters{
  display:flex;
  align-items: center;
}

// Special page section with filters for a table,
// Displayed above main content section
.page-section-filters{
  .card{
    background-color: $content-bg;
    box-shadow: 1px 1px 1px 0 rgba(90, 90, 90, 0.25);
  }

  .keeper-lock{
    display:none;
  }

}

.page-section-filters, .above-table-filters{

  .loading-overlay{
    display:none;
    position:absolute;
    background-color: $white;
    opacity:0.6;
    top:0;
    right:0;
    bottom:0;
    width:100%;
    z-index:1000;
    &.show{
      display:block;
    }
  }
  .min-250{
    min-width:250px;
  }
  .navbar{
    padding:0;
  }
  .card-body{
    padding:1.25rem;
  }

  //Styled box around a filter element in the filter bar
  //(react-select filters don't need this class)
  .boxed-filter{
    position: relative;
    border: 1px solid $gray-300;
    border-radius: 4px;
    min-height:50px;
    white-space: normal;
    padding: .5rem;
    background-color: $white;
    color:$gray-600;
    &.new-boxed-filter {
      min-height:40px;
      padding: 0 .3rem;
    }
  }

  .btn-toggle{
    border: 1px solid $gray-400;
    height: 50px;
    padding: 2px;
    border-radius: 4px;
    .btn{
      padding: 8px 15px!important; //adjust btn-sm padding for this, to match height of other filters
      &.btn-outline-secondary{
        border: 1px solid $gray-300;
        color:$gray-500; //non-selected state
        &:hover{
          color: $white;
        }
      }

      &:focus{
        box-shadow:none;
      }
    }
  }

  // Styled reactstrap DropdownToggle component (button that opens a dropdown that contains filters)
  // May contain icon, title, and summary of selected filters
  .filter-group-dropdown {
    min-width:275px;
    padding-left: 2.3rem !important;
    line-height: 1;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    color: #000;

    .title{
      line-height: 1.1;
      font-size: 1.125rem;
      font-weight: 600;
      text-transform: uppercase;
    }
    small {
      text-transform: none;
      display: inline;
      strong {
        text-transform: uppercase;
      }
    }

    .fa-icons {
      //font-size: 1.8rem;
      margin-right: .2rem;
      color: #28a7f7;
      position: absolute;
      top: 8px;
      left: 8px;
    }


  }

    // A version of the EnhancedSelect, for the filter bar.
    // Widget needs to have option classNamePrefix="select-filter"
    // and hideSelectedOptions={false}
    .select-filter{
      // Custom styles for react-select
      .enhanced-select__control{
        min-height:50px;
      }
    }

  // New version of filter dropdown that is small and only contains an icon
  // instead of a summary of applied filters
  // (applied filters are instead shown in tags)
  .simple-filters-dropdown {
    .filter-group-dropdown {
      min-width: initial !important;
      padding-left: 1.6rem !important;
    }
  }

  // A dropdown menu containing several stacked filters
  // (ReactStrap DropdownMenu component, .dropdown-menu)
  .select-filters-dropdown{
    min-width:600px;
    padding: 1rem;
    .row{
      align-items: center;
      margin-bottom:0.5rem;
      &:last-child{
        margin-bottom:0;
      }

    }

    h5{    font-size: 1rem;
      text-transform: uppercase;
      font-weight: 600;
      white-space: pre-wrap;
      word-break: keep-all; /*this stops the word breaking*/
    }
    // shorter height (back to default) when in dropdown-menu
    .select-filter{
      .select-filter__control {
        min-height: initial;
      }
      label {
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }

  }

  //Date picker and reset controls, see RightDateAndControls component
  .right-date-controls{
    .nav-link{ color:$black;} //Date picker date display


      .timezone-toggle, .tz-display{
        color:$gray-600;
        font-size:0.85rem;
        &.btn{
          border:none!important;
          min-height:auto !important;
        }
      }
    }

  .filter-summary {
    margin-top:1rem;
    .active-filter {
      padding:0.3rem 0.5rem;
      border:1px solid $gray-300;
      background-color: $gray-100;
      border-radius: 4px;
    }
    .btn {
      padding:0 0 0 8px;
    }
    .btn-apply{
      animation: tilt-shake 200ms 10 ease-out;
    }
  }

} //filters


.hideaway-panel {
  .hp-header{
    .btn{
      color:$body-color!important;
      font-weight:600;
    }
    svg {color:$primary;}
  }
  .hp-content {
    -moz-transition: height 350ms;
    -ms-transition: height 350ms;
    -o-transition: height 350ms;
    -webkit-transition: height 350ms;
    transition: height 350ms;
    height: 0;
    overflow: hidden;
  }


}

// Page section used above some lists for display of aggregate data: data tiles, list tiles, detail tables.
// Tiles are arranged using css grid. Tile component is from the Mastodon UI library.
.dashboard-section {
  .mst-tile-card {
    margin:0;
    min-height:64px;
    &.horizontal {
      .card-text {
        // shrink font size: for larger numbers, default font size overflows
        font-size:24px;
      }
    }

  }
  .tile-grid {
    display: grid;
    //each implementation will probably override these grid columns
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 16px;
  }

  .expand-panel .btn {
    margin-bottom:-1em;
  }

  //Expanding detail panel
  .panel{
    max-height:0;
    //max-height:420px;
    overflow:hidden;

    transition: max-height 200ms ease-out;
    &.show{
      //transition:2s;
      max-height:420px;
      //overflow:visible;
    }
  }
  //.panel {
  //  display:grid;
  //  grid-template-rows: 10px;
  //  transition: all 1s ease;
  //
  //  &.show {
  //    grid-template-rows: 420px;
  //    //max-height:420px;
  //  }
  //  .panel-body {
  //    overflow:hidden;
  //  }
  //}

  // Section with tables that show aggregate data. Tables are in an expanding panel of limited height,
  // with horizontal and vertical scrolling, and sticky headers.
  .agg-detail {
    .table-container {
      max-height: 350px;
      overflow: auto;
      border: 1px solid $gray-200;
      border-radius: 4px;
    }

    .agg-detail-table {
      width:max-content; //prevents sticky table header from being clipped by parent container
      margin-right:1em;
      .th {
        font-size:0.8rem!important;
      }
      .td {
        font-size:0.8rem;
      }

      .thead {
        position:sticky;
        top:0;
        z-index:1;
        background-color: $white;
      }

    }
  }

  // A section with a scrolling fixed height list on the left and a chart on the right
  .list-and-chart {
    .chart-grid {
      display: grid;
      grid-template-columns: 28% 70%;
      grid-gap: 18px;
      max-height: 250px;
      // overflow:hidden;

      .scrolling-list {
        height:220px;
        overflow:hidden;
        .form-section {
          margin-bottom: 0;
          .list{
            height:183px;
            overflow-y:auto;
            overflow-x:hidden;
            padding-right:0.35rem;
          }
        }
        table {
          table-layout: fixed;
          td{
            word-break: break-word;
            label {
              font-weight:600;
            }
           // white-space: pre-wrap;
          }
        }
      }

      &.integrations-rejected {
        .scrolling-list {
          height:180px!important;
        }
        .list {
          height: 140px!important;
        }

        select {
          width: 200px;
          margin: 0;
        }

      }
    }
  }

} //dashboard section




/* #################################
 * LOADERS/PROGRESS
 * #################################
 */
.loading-bar-container{
  position: absolute;
  z-index: 950;
  top: 50px; /*below header*/
  right: 0;
  bottom: 0;
  left: 0;
  width:100%;
  height:100%;

  &[data-state="hidden"]{
    height:6px;
    bottom:auto;
    .loading-bar-overlay {
      opacity: 0;
      transition: opacity;
      transition-duration: 0.2s;
      transition-timing-function: linear;
      img{display:none;}
    }
    .loading-bar-message {
      display: none;
    }

  }
  &[data-state="active"]{
    .loading-bar-overlay {
      opacity:1;
      transition: opacity;
      transition-duration:0.15s;
      transition-timing-function: linear;
    }

  }

  .loading-bar-overlay{
    z-index: 801;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top:6px;
    width:100%;
    height:100%;
    will-change: opacity;
    background-color:#fff;
    img{
      //logo watermark
      position:relative;
      width:300px;
      top:100px;
      left:calc(50% - 90px);
      opacity:0.1;
    }
  }


  .loading-bar{
    background-color: #8ef184;
  }

  .loading-bar-message{
    position: absolute;
    top: 100px;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    font-weight:bold;
    color: $gray-600;
    font-size:1.2em;
    z-index: 830;
    background-color:$white;
    margin:auto;
    width:300px;
    padding:1em;
  }
} /* loading-bar-container */

//smaller logo watermark when overlay is inside a page section or modal
.card-body, .modal-body {
  .loading-bar-container{
    top:0;
  }
  .loading-bar-overlay {
    top:6px;
    img {
      top: 0;
      display: none;
    }
  }
}


.disable-selection {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtms-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}

//Block-ui Loader component
.block-ui-container{
  .block-ui-overlay{
    opacity:0.8;
  }
  .block-ui-message-container{
    top:35%!important;
    transform: translateY(-40%);
  }
}


.svg-loader-container {
  position: absolute;
  z-index: 800;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow:hidden;
  display:none;
  .svg-loader{
    position:relative;
    top:30%;
    //left:calc(50% - 50px); //100 px is default width, can be overridden in component
  }
  &.active{
    display:block;
  }
  &.inline{
    position:relative;
    right:auto;
    bottom:auto;
    width:auto;
    height:auto;
  }

}

// smaller version of reactstrap spinner, use size="xs"
.spinner-border-xs, .spinner-grow-xs {
  width:0.75rem!important;
  height:0.75rem!important;
}

/* #################################
 * FORMS AND INPUTS
 * #################################
 */

//card-like division of form content, whiter than background
//TODO: remove from shared rule css and keep here
.form-section{
  padding:1rem;
  background-color:$white;
  border-radius:6px;
  &.border {
    border:1px solid $gray-200;
    box-shadow: 1px 1px 1px 0 rgba(90, 90, 90, 0.20);
  }

  .form-section-header{
    color:$gray-700;
    font-size:1rem;
    font-weight:700;
    margin-bottom:1rem;
  }
  //Used for description below header
  p{
    margin-top:0;
    margin-bottom:1rem;
  }

  .data-row {
    font-size:0.9rem;
    display: flex;
    padding-bottom: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid $gray-100;

    label {
      font-weight: 600;
      // color: $gray-700;
      margin-right: 1.5rem;
      min-width: 150px;
    }

    &:last-child{
      border-bottom:none;
    }

    div:last-child{ //data value
      flex-grow:1;
      word-break:break-word;
    }

    .sub-row{
      display:flex;
      .key{
        flex-basis:124px;
        font-weight:500;
        margin-right:1em;
      }
    }

  }
}

.form-group {
  legend {
    border-bottom:1px solid #cecece;
    margin-bottom:20px;
    font-size:1.2rem;
  }

}
.form-control {
  border-width: 2px;
  transition: border-color ease .5s, box-shadow ease .5s;
  //color: $blue; //DMS One but I don't like this
  &.tiny { max-width: 140px; }
  &.teeny { max-width: 100px; }

  &:not(.is-invalid){
    border-color: #DBE5EF;
  }


  & .form-control-lg {
    padding: 15px 10px;
  }
  font-weight: 500;

  &:not(.is-invalid):focus{
    border-color: $cyan !important;
    border-width: 2px;
  }

  &.is-invalid ~ .input-group-text{
    border-color: $form-feedback-invalid-color!important;
  }


  &::placeholder{
    color:$gray-500 !important;
  }
  //&:hover{ // CP: do we need this?
  //  border-color:#b3b3b3; //matches react-select default
  //}
  //&:focus{
  //  box-shadow: 0 0 0 1px #2684ff; //matches react-select default focus outline
  //}

  &:disabled{
    color:#ccc;
  }
} //.form-control


// Embiggen the tiny text used in reactstrap <FormText>
small.form-text{
    font-size: 0.9rem;
    margin:0.25rem  0;
}

.form-check {
  &.detailed {
    border:1px solid $gray-400;
    background-color: rgba($gray-100, 0.5);
    padding:0.25rem;
    margin-bottom:0.5rem;
    input {
      margin-left:0;
      margin-right:0.5rem;
      float:none;
    }
    .form-check-label {
      font-weight:600;
    }
    .form-text {
      font-weight: normal;
      display:block;
      margin-left:1.6rem;
      color:$gray-800!important; //override text-muted
    }
    &.selected {
      background-color: rgba($primary, 0.25);
      color:$blue-600;
      .form-text {
        color:$blue-600!important;
        font-weight:700;
      }
    }
  }
}

//In a form grid, sometimes we hide the form element and show text instead.
//Since the Label element in the left column has padding, text in right column should
//match in order to align (Wrap text in block with this class)
.col-form-text {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

/* focus bg color in inputs
input.form-control[type=text]:focus,
textarea.form-control:focus{
  background-color: rgba(250, 250, 210, 0.25);
}*/

.simple-select {
  &.invalid {
    div {
      border-color: $danger;
    }
  }
}



// Component: IconCheckbox
.icon-checkbox{
  padding-left:0;

  .cbx-icon {
    cursor:pointer;
    margin-right:0.4rem;
    color: $gray-500;
    background-color:$white;
  }

  .form-label {
    margin-bottom: 0;
  }

  &.checked{
    ///color: $gray-600;
    .cbx-icon{ color:$primary}
  }

  &.disabled{
    color: $gray-400;
    .cbx-icon{
      //color:$gray-400;
      cursor:not-allowed;
    }
  }

}

// Component: Forms/InputWithIcon
// Reactstrap input with font awesome icon in background to right or left
// Used in search filter and other places
.input-icon-container{
  position:relative;
  display:inline-block;
  &.expands{
    display:block;
  }

  &.icon-left input{
    padding-left: 32px;
  }
  &.icon-right input{
    padding-right: 32px;
    text-align:right;
  }
  .input-icon {
    color:$gray-500;
    position:absolute;
    top:12px;
    cursor:pointer;
    &.left{left:12px;}
    &.right{right:12px;}
    &.clear { cursor:pointer; color:$gray-400}
    &.is-invalid{
      //enforce bootstrap's red X icon for invalid state
      background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E")
    }
  }
  //use inputSize="sm" attribute when smaller input is desired
  &.size-sm{

    input{
      padding-left: 20px!important;
    }
    .input-icon.left{
      left:6px;
      top:8px;
    }
  }
}

//grayed-out default select option
select option[data-default] {
  color: $gray-200;
}

// Method of applying a placeholder to Select input.
// Need to set 'required' attribute on select, and
// <option value=""  data-default hidden> on the placeholder option
select:required:invalid {
  color: $gray-500;
}

/* Text search entry. Used in.page-section-filters (main filters above table) and AccountMegaMenu */
.search-filter{
  &:focus{
    border-color: $primary!important;
  }
  .search-filter-wrapper{
    position: relative;
    min-width: 300px;
  }
  .input-icon{
    color:$primary;
    &.left{left:0;top:22%;}
  }
  .form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $gray-200;
    padding-top: 0.25rem!important;
    padding-bottom: 0.25rem!important;
    &:focus {
      box-shadow: none;
      border-bottom-width: 2px;
      //border-bottom-color: $primary;asfasdf
    }
  }
}


.btn-outline-light {
  border:1px solid $gray-300!important;
  color: $gray-700!important;
  text-transform: none!important;
}

// button style with very round border.
// <Button size="sm" outline color="rounded" active />
.btn-outline-rounded {
  border:1px solid $gray-500!important;
  color: $gray-600!important;
  text-transform: none!important;
  &.btn-sm {
    border-radius:17px!important;
  }
  &.btn-xs {
    border-radius:12px!important;
  }
  &.active {
    border:1px solid $primary!important;
    color: $primary!important;
  }

  &:hover {
    border:1px solid $primary!important;
  }
}

//event smaller than btn-xs
.btn-xxs {
  padding: 3px 3px!important;
  font-size: .7rem!important;
  height:18px;
  .svg-inline--fa {
    vertical-align: 0.875rem;
  }
}

/* Component: PasswordWithToggle. Has icon that toggles password visibility.
   Consists of an input group with an input (.form-control), and appended input-group-text
    that holds the password toggle icon. Both are styled so that it looks like one form input
    with an icon at right. */
.password-toggle{
  position:relative;
  overflow:hidden;
  .form-control{
    //padding: 0 2rem 0 0.375rem; //right padding makes room for visibility icon toggler
    border-right:none;
    //padding:0;
    width:95%;// gives room for Keeper icon

    &:focus ~ .input-group-text {
      //Same as general .form-control
      border-width: 2px;
      border-color: $cyan !important;
      transition: border-color ease .5s, box-shadow ease .5s;
    }

    &.is-invalid {
      //Reposition invalid icon
      // overrides this: background-position: right calc(0.375em + 0.225rem) center;
      background-position: right 0 center;
      &:focus{
        // We don't want box shadow because the right border of the shadow cuts across the white space and looks bad.
        // No uncomplicated way to disable one side of a shadow.
        box-shadow:none!important;
        & ~ .input-group-text {
          border-color: $form-feedback-invalid-color!important;
          border-width: $input-border-width;
        }
      }
    }
  }

  //&:focus-within{
  //  // imitate .form-control:focus
  //  border-color: #a3d9fc;
  //  outline: 0;
  //}

  input{
    //border-color:transparent;
    //border:none;

  }

  .input-group-text {
    color: initial;
    border-left:none;
    background-color: $white;
    border-top-right-radius: $input-border-radius!important;
    border-bottom-right-radius: $input-border-radius!important;
  }

  svg{
    //position:absolute;
    //top:10px;
    left:auto;
    right:auto;
    color:#aaa;
    cursor:pointer;
  }
  &.visible{
    svg{
      color:dodgerblue;
    }
  }
  //shift icon to make room for bootstraps red X icon for invalid state
  &.is-invalid svg{
    right:30px;
  }
}

/*========FORM VALIDATION========*/
.invalid-warning{
  color:#b51e07;
}

.required {
  font-size:0.8em;
  color:#b51e07;
  &.asterisk{
    position: relative; top: -0.3em; font-size: 80%;
  }
}

/* ############################################
 * OTHER LAYOUT: prompts, notifications, modals
 * ############################################
 */
#password-rules{
  ul{ list-style-type: none;}
  li {font-size:0.85rem; color:$gray-700;}
}


.delete-prompt{
  font-weight:600;
  font-size: 0.9rem;
  i{ margin-right:0.5rem;}
  .btn{ padding:0 0.5rem;}

}

//Remove dark coloring of dropdown item when you click because I don't like it
.dropdown-item:focus{
  outline-color:transparent;
}


/* Blackout dates: time select below calendar dropdown. Make it stand out more. */
.flatpickr-time{
  background-color: #faf9ee;
  border-top:1px solid #666;
}

// General tab style
.mastodon-tabs {
  //background-color:$white;
  cursor:pointer;
  margin-bottom:1rem!important; //.nav will override
  border-bottom:1px solid $gray-300;
  .nav-item {
    position:relative;
    margin-top: -$nav-tabs-border-width;
    .tab-icon-error{
      position:absolute;
      top:7px;
      left:50%;
      margin-left:-8px;
      color:$red;
      z-index:10;
    }

    .nav-link {

      padding: 1.4rem 1.4rem 0.4rem 1.4rem;
      color: $gray-900;
      font-family: $font-family-sans-serif;
      border-bottom: 3px solid transparent;

      &.disabled {
        color: $nav-link-disabled-color;
        background-color: transparent;
      }

      &.active {
        font-weight: 700;
        border-color: $red;

      }

      /* "more" menu, for when there are too many tabs */
      .tab-more{
        .btn {
          padding:0;
          border:0;
          vertical-align: initial;
          font-size:inherit;
        }
        .btn-link{
          color:$gray-700;
          text-decoration: none;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }

}


/* tab style used in modals */
.modal-content{
  &.nav-tabs{
    border:none;
  }
  //.mastodon-tabs {
  //  margin-bottom:1.2rem;
  //  border-bottom:1px solid $gray-300;
  //  .nav-item{
  //    color: #fff;
  //
  //   // font-size:16px;
  //    margin-right:16px;
  //    cursor: pointer;
  //    .tab-icon-error{
  //      top:-6px;
  //    }
  //    .nav-link{
  //      border:none;
  //      text-align:center;
  //      border-bottom:2px solid #fff;
  //      color: #acacac;
  //      font-weight:700;
  //      padding: 0.6rem 0.5rem 0.2rem .5rem;
  //      &.active{
  //        border:none;
  //        border-bottom:2px solid blue;
  //        color:#666!important;
  //      }
  //      &:hover {
  //        border:none;
  //        border-bottom:2px solid $gray-400;
  //        color:#666!important;
  //      }
  //      span{
  //        vertical-align:middle;
  //        margin-right:6px;
  //      }
  //    }
  //
  //
  //  }
  //}

}



/* Tabbed Forms: fade transition */
.tab-pane.active { animation-name: fadeInTab; animation-duration: 300ms; animation-timing-function: linear; }
@keyframes fadeInTab { from { opacity: 0; } to { opacity: 1; } }

/* async load of individual form field */
.inline-loading {
  font-style:italic;
  font-size:0.8em;
}



// A wrapper for a widely used reactstrap Alert for errors or notifications
// Used at top of forms or sometimes in table rows for no-data cases
.standard-alert{
  display:flex;
  .standard-alert-icon{
    flex-basis:30px;
    align-self: flex-start;
    margin-right:4px;
  }
  .standard-alert-content{
    flex-grow:1;
  }
  .btn-link{
    padding:0;
    vertical-align: baseline;
  }
  //inline version to put next to form elements, meant to be short
  &.inline{
    display:inline-flex;
    margin-left:4px;
    .standard-alert-icon{ margin-right:8px;}
    .standard-alert-content{
      white-space:nowrap;
    }
  }

  &.alert-light{
    background-color:transparent;
    color: $body-color;
  }

  &.alert-info{
   // background-color:#f8f9fa;
   // border-color:#efefef;
  }

  &.validation{
    background-color:#fffcf9;
    border:1px solid $gray-300;
    svg{color:$red;}
    .standard-alert-content{
      font-weight:600;
      li{font-weight:normal;}
    }

  }
}

/* #################################
 * LISTS AND TABLES
 * #################################
 */
//Buttons in right column
.list-actions{
  display:flex;
  justify-content: flex-end;

  .action-item{
    display:flex;
    align-items:center;
    margin-right:0.5rem;
    color: $table-color;
    svg{
      margin-right:.25rem;
    }
    &:hover{
        color:$link-color;
      svg{
        color:$link-color;
      }
    }
  }

}

.react-table-container{
  //help prevent annoying yo-yoing of height between rows and no-rows state of table
  &.mh-400{
    min-height:400px;
  }
}

//small inline display of ID value, usually shown with the entity name
.entity-id {
  margin: 0 0.25rem;

  font-size: 0.7rem;
  display: inline-block;
  padding: 0 3px;
  background-color: $gray-200;
  border-style: solid;
  border-width: 1px;
  border-color:$gray-400;
  line-height: 14px;
  border-radius: 2px;
}

/*
 * React-table-7
 * (additional styles for specific table layouts in Components/Table)
 */

.table{

  // Variable files set $table-color equal to $body-color,
  // but something insists on re-setting it to $gray-700 (too light).
  // Can't find the source of that override, so forcing it here
  color: $body-color!important;

  &.striped {
    .td.odd {
      background-color: rgba(0, 0, 0, 0.01) !important;
    }
  }

  .th{
    //
    //div:first-of-type{
    //  white-space: break-spaces!important;
    //  word-wrap:normal;
    //}
    &.nowrap {
      white-space: nowrap!important;
    }
    .label{
      white-space: nowrap;
    }
    &.numeric {
      text-align: right;
    }
  }

  .td.subcomponent{
    //padding:0;
    //if a table is used as a subcomponent
    .table-container{
      min-height:auto;
    }
  }

  .tr {
    &:hover {
      .list-actions .action-item {
        color:$link-color;//$table-color;
      }
    }
  }
  .td{
    font-size:0.9rem;
    .btn{
      font-size:0.9rem;
    }
    //grid doesn't really have rows so we need a style for end of "row" cell
    //Could also do .td:nth-child(5n), but this would need to be tweaked according to number of columns
    &.row-end{
      border-right:none;
    }

    &.numeric {
      text-align: right!important;
    }

  }

  //Override a style of the bootstrap .table class
  & > :not(:first-child) {
    border-top: none!important;
  }

  //& > :not(.subcomponent) > * > * {
  //  padding: 0.5rem 0.5rem;
  //  background-color: var(--bs-table-bg);
  //  border-bottom-width: 0!important; // 1px;
  //  box-shadow: none!important; // inset 0 0 0 9999px var(--bs-table-accent-bg);
  //}
  // end override bootstrap

  .subcomponent{
    .table-container {
      border-bottom-width: 0!important; //overrides bootstrap ".table > :not(.subcomponent) > * > * "
      tfoot{
        border-style:inherit;
      }
    }
    .subcomponent-container{
      position:relative;
      background-color: #f8f9fa;// #ebeff3;// #fafcfd;
      padding:1rem;

      //-webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
      //-moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
      //box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.8);
      //-webkit-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.4);
      // -moz-box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.4);
      box-shadow: inset 0 20px 20px -20px rgba(0,0,0,0.4);

      .pointer{
        position:absolute;
        top:0;
        left:100px; // middle: calc(50% - 16px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 16px 0 16px;
        border-color: #ffffff transparent transparent transparent;
      }
    }
  }
}

.table-container{
  position:relative;
  &.loading {
    min-height: 250px;
  }
  .table-loader{
    position:absolute;
    display:block;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: rgba(255, 255, 255, 0.8);
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    z-index:1;
  }
  .table-loader > div {
    position:absolute;
    width:100%;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    top:90px;
    left:calc(50vw - 40px);
    text-align:inherit;
    display:inline-block;
    -webkit-transform: translateY(50%);
    transform: translateY(50%)
  }
}

.table-search-container {
  /*padding:4px;*/
  margin-bottom:8px;
  /*background-color:#fbf4d2;
  border-radius:4px;*/
  .table-search-field {
    background-color:#fff;
  }
}


  // used to be #report-controls
  .filter-controls{
    flex-basis:124px;
  }

/* react-table classes */
.pagination-bottom, .pagination-top{
  margin-top:1rem;
}

/* Custom pagination styles */
.pagination-container{
  display:flex;
  justify-content: space-between;
  align-items:baseline;
  label{
    //color:$gray-600;
    margin: 0 0.5rem 0 0;

  }
  .form-control, .form-select{
    display:inline-block;
    width:auto;
    //height:calc(1.5rem + 2px);

  }
  .page-summary{
   // color:$gray-600;
  }
  .page-show{
    //margin:0 1em;
  }
  .page-jump{
    .form-control{
      max-width:3rem;
    }
  }

  .pagination-default{
    //flex-grow:1;
    .page-item{
      cursor:pointer;
      margin:0 10px;
      &.disabled{
        cursor:auto;
      }

      .page-link{
        //font-weight:400;
        background-color:transparent;
        color:$primary;
        border:none;
       //border-radius:4px;
        padding:0.1rem 0.2rem;
        &:active{
          box-shadow:none;
        }
      }
      &.active{

        .page-link{
          font-weight:700;
        //  color:$white;
          background-color:$gray-100;
          border-radius:4px;
        }

      }
    }
    .ellipsis{ margin:0 4px;}
  }
}

/*
 * Reactstrap table
 */

.table {
  th > svg {
    // This reactstrap style selector does odd things if icon is in table header:  ".table > :not(caption) > * > * "
    // Fix:
    padding: 0 !important;
  }
}
/*=======WIDGETS========*/
  .mst-data-rows {
    .row-divider {
      border-top-color: $gray-200;
    }
  }
  // Component: StatusButtonGroup
  .status-toggler{
    &.enabled {
      cursor: pointer;
    }

    .dropdown-menu{
      min-width:5rem;
      .dropdown-item{
        padding:0.25rem 1rem;
        &.active{
          background-color: inherit;
          color:inherit;
          font-weight:bold;
        }
      }
    }
    // Status notes feature, not in main branch
    //&.notable{
    //  .dropdown-menu{
    //    min-width:24rem;
    //    -webkit-box-shadow: 3px 3px 9px 0px rgba(90, 90, 90, 0.5);
    //    -moz-box-shadow:    3px 3px 9px 0px rgba(90, 90, 90, 0.5);
    //    box-shadow:         3px 3px 9px 0px rgba(90, 90, 90, 0.5);
    //  }
    //  textarea{
    //    font-size:0.9rem;
    //    &:focus{
    //      box-shadow: none;
    //      outline:none;
    //    }
    //  }
    //
    //}

    .current-status-icon{
      &.active { color:$success; }//limegreen;
      &.paused { color:$warning;} //gold
      &.archived { color:$danger;}
    }

    svg{
      &.fa-xs{
        font-size:0.6rem;
        margin-bottom:0.1rem;
      }
     // width:0.6rem;

    }
  }



  .rc-slider{
    height:36px;
    margin-top:10px;
  }

  .help-popper-icon{
    color:$gray-500;
    &:focus{
      outline:none;
    }
  }
  .help-popper{
    .popover-header{
      font-size:0.9rem;
    }
    .popover-body{
      font-size:0.8rem;
    }
  }

.thin-scrollbar{
  &::-webkit-scrollbar{
    width:6px;
  }
  &::-webkit-scrollbar-thumb{
    //draggable scroller
    background-color:steelblue;
    border-radius:2.5px;
  }
  &::-webkit-scrollbar-track{
    background: #b6efe1;
    border-bottom-right-radius:8px;
  }
  &::-webkit-scrollbar-track-piece{
    //uncovered track
    background-color: #fff2da;
  }
  &::-webkit-scrollbar-button{
    background-color:#ccc;
  }

}



.date-range-dropdown.dropdown-menu{
  box-shadow: 4px 4px 5px 1px rgba(206, 206, 206, 0.5)
  //box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2);
}

// Dashboards, hide tile icons to prevent some overflow issues at smaller widths
@media (max-width: 1100px){
  .dashboard-section {
    .mst-tile-card .icon-body{
      display: none!important;
    }
  }
}
