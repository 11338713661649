@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import "../Layout/common";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/@thedmsgroup/mastodon-ui-components/lib/assets/scss/theme.scss";
@import '../Layout/faBackground';


html, body{
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
}


label{
    margin-bottom:0;
}

h1{
    font-size:24px;
}



.row-no-pad {
    margin-left: 0;
    margin-right: 0;
    [class*="col-"] {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}


#public-layout {
  min-height: 100%;
  margin-bottom: -130px;
  #public-content {
    padding-top:70px;
    min-height:100%;
    height:auto !important;
    margin: 0 3rem 0;

  }
}

#entry-layout {
  min-height:100%;
  margin-bottom:-130px;


  .main-navbar {
    background-color: $primary;
    height: 50px;
  }

  .blue-top{
    background-color: $primary;
    height: 240px;
    border-bottom: 2px solid $white;
  }

  #entry-form-container {
      min-height:250px;
      margin-top:-200px;

    .entry-form-panel {
      min-height:200px;
      background-color: $white;
      border:1px solid $gray-300;
      border-radius: 8px;
      padding:2em;
      box-shadow: 4px 4px 9px 0px rgba(0, 0, 0, 0.1);// 4px 4px 9px 0px rgb(0 0 0 / 20%)
    }

      form { animation-name: fadeInForm; animation-duration: 700ms; animation-timing-function: linear; }
      @keyframes fadeInForm { from { opacity: 0; } to { opacity: 1; } }

  }

  .standard-alert{
    border:1px solid $gray-400;
  }

  //chrome autofill screwing with colors
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
   {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    //background-color:#fff !important;
  }
}


header.header-main {

  .main-navbar {
    background-color:$primary;
    height: 50px;

    .nav-item {
      margin-left:0.5rem;
    }

    .nav-link,
    .dropdown {
      .dropdown-toggle {

        // border-radius: 0.25rem; //$border-radius;
        // transition: background-color .2s ease;

        //&:hover:not(.active){
        //  background-color: $gray-100;
        //}
        &.active {
          //background-color: rgba(40, 167, 247, 0.1);
          font-weight: 700;
        }

      }

      .dropdown-menu{
        box-shadow: 0px 5px 20px rgba(102, 102, 102, 0.2)
      }
    }



    .app-select {
      .dropdown-header {
        padding-top: 1rem;
      }
    }
  }
}

#account-mega-menu{


  &.body{
    padding:0.85rem;
    .menu-rows{
      display:flex;
      flex-flow: column;
      .account-row{
        height:60vh;
        overflow:hidden;
      }
    }
  }

  .search-wrapper {
    position: relative;
    margin-bottom: 1rem;

    i {
      font-size: 24px;
      line-height: 30px !important;
      position: absolute;
      top: 5px;
      left: 0;
    }

    .form-control {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $gray-400;
      &:focus {
        box-shadow: none;
        border-bottom-width: 2px;
        border-bottom-color: $primary;
      }
    }
  }

  .account-header{

    span{
      display:inline-block;
      color: #3aa7f7;
      font-weight:600;
      margin:8px;
    }

    h5 {
      font-size: 0.875rem;
      text-transform: uppercase;
      color: $gray-700; //#28a7f7;
      font-weight: 600;
      line-height: 2.5rem;
      margin-bottom: 0;
    }
    div:first-child{
      border-right:1px solid #e1e1e1;
    }


  }
  .account-list-col{
    height:100%;
    overflow-x:hidden;
    overflow-y:auto;

    .nav-item:hover {
      position:relative;
      cursor: pointer;
      .account-actions {display:inline-block;}
    }
    .nav-link {
      position:relative;
      color: $body-color!important;
      font-weight: normal;
      &:after {
        position: absolute;
        right: 8px;
        top:10px;
        content: url(fa-background(chevronRight));
        color: $gray-400;
        display:block;
        width:0.5em;
        height:0.5em;
      }
      &.active {
        font-weight:bold;
      }

      &:hover:after,
      &.active:after {
        color: black;
      }

      &.active:hover {
        background: rgba($primary, .02);
      }
    }
    .account-actions {
      display:none;
      position:absolute;
      top:10px;
      right:20px;
      width:80px;
      cursor:pointer;
      color:#666;
      svg{
        margin:0 6px;
      }
    }
  }

  .account-orders-col{
    height:100%;
    overflow-x:hidden;
    overflow-y:auto;
    .order-header{
      h5 {
        font-size: 0.8rem;
        text-transform: uppercase;
        color: $gray-600;
        font-weight: 600;
        line-height: 1.8rem;
        margin-bottom: 0;
      }
    }

    .order-row{
      display:flex;
      justify-content:space-between;
      flex-wrap:nowrap;
      padding:4px;
      &.selected{
        background-color:#d9f0fe;
      }
      &:hover{
        background-color:rgba(0,0,0.02,0.02)
      }

      .order-status{flex-basis:20px;}
      .order-name{
        flex-basis:200px;
        //overflow:hidden;
        //white-space: nowrap;
        //text-overflow: ellipsis;
        cursor:pointer;
        &:hover{
          text-decoration: underline;
        }
      }

      .order-vertical{flex-basis:140px;}
      .order-product{flex-basis:50px;}

      .order-actions{
        flex-basis:100px;
        svg{
          color:#666;
          margin:0 6px;
          cursor:pointer;
        }
      }

    }
  }
}

#select-account-dropdown {
  width: 900px;
  border: none;
  //box-shadow:0 4px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 7px -2px rgba(0, 0, 0, 0.12), 0 6px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 20px rgba(102, 102, 102, 0.2)
}



//keep footer at bottom when main content is short
//(content area needs negative bottom margin)
.push-footer{
  height:150px;
}

#footer {

    height:100px;
    //width:100%;
    padding:4px 3rem;
    background-color:transparent;
    font-size:0.8rem;
    .footer-contents{
        display:flex;
        color:#666666;
        .navbar{
          padding:0;
        }
        .nav-item{
            margin-right:2rem;
            a, a:link, a:visited{
                color:#666666;
            }
        }
    }

}

/* ANIMATION */
/*Navbar Dropdown Animation*/
.dropDown {
    -webkit-animation-name: dropDown;
    animation-name: dropDown;
    transform-origin: 0 0;
    transition: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 10rem) {
    .dropdown-menu.animate {
        animation-duration: 0.3s;
        -webkit-animation-duration: 0.3s;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
    }
}

@keyframes dropDown {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }

    0% {
        transform: scale(0);
        opacity: 0;
    }
}

@keyframes tilt-shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0deg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

.help-popper{
  .popover{
    box-shadow: 3px 3px 6px 1px rgba(0,0,0,0.3);
  }
  .popover-header{
    font-size:0.9rem;
  }
  .popover-body{
    font-size:0.8rem;
    //background-color:$gray-100;
  }
}

/*
 * OFF-CANVAS
 * Reactstrap modal panels that slide in from the side
 */
.offcanvas {
  .offcanvas-header {
    background-color: $primary;
    color:$white;
  }
}

/* MODALS
Custom modal sizes. In reactstrap modal component, use attribute size="xlg", or size="fullscreen"
*/

/* Full: covers almost all of the screen */
.modal-full {
  min-width: 96vw;
  .modal-content {
    min-height: 96vh;
  }
}
.modal-xlg {
  min-width: 80vw;
  .modal-content {
    min-height: 92vh;
  }
}

//@media (min-width: 960px) {
//    .modal-xlg {
//        max-width: 900px;
//    }
//}
//
//@media (min-width: 1200px) {
//    .modal-xlg {
//        max-width: 1100px;
//    }
//}

.modal-content{

  .modal-header{
      padding:0.7rem 0.7rem;
      border-bottom:none;
      h5 {font-size:1.1rem!important;}
  }
  .modal-subheader{
      // not part of reactstrap
      padding: 0 0.7rem;
      margin-top:-0.5rem;
      font-weight:bold;
      color:$secondary;
  }

   // An inline subheader:  "TITLE | subheader"
   .modal-title > .modal-subheader {
     font-size:initial;
     color:$secondary;
     padding: 0;
   }

  .modal-content-background {
      background-color: $body-bg;
      padding:0.75rem;
  }

  .modal-content-footer{
      margin-top:1em;
    text-align: right;
      .btn{
          margin-left:0.5rem;
      }
  }

  // hidden input for copying to clipboard
  #copy-url{
    position:absolute;
    left: -500px;
  }
}



/* slide out bootstrap modal dialog  https://bootsnipp.com/snippets/7ngvl */
@media (max-width: 576px){
    .modal-dialog.modal-dialog-slideout {width: 80%}
}

// Slides from left (for right, use -100%, margin-right auto)
.modal-dialog-slideout {min-height: 100%; margin: 0 0 0 auto ;background: #fff;}
.modal.fade .modal-dialog.modal-dialog-slideout {-webkit-transform: translateX(100%);transform: translateX(100%);}
.modal.fade.show .modal-dialog.modal-dialog-slideout {-webkit-transform: translate(0);transform: translate(0);flex-flow: column;}
.modal-dialog-slideout .modal-content{border: 0;}
